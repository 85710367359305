import { useState } from "react";
import {
	alpha,
	Box,
	Button,
	darken,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormHelperText,
	hexToRgb,
	IconButton,
	Typography,
	useTheme
} from "@mui/material";
import { Add, Close, DeleteOutline, Edit } from "@mui/icons-material";
import { DataGridPro, type GridColDef } from "@mui/x-data-grid-pro";
import {
	FormCheckbox,
	FormInput,
	FormPhoneInput,
	httpService,
	LookupItem,
	RadioGroupField,
	RadioGroupFieldOption,
	useForm,
	useLookupQuery,
	useMyAccount,
	usePopupAlert,
	zodValidator
} from "@sal/onevent-portal";
import { type BaseConsignment } from "@sal/onevent-portal";
import type { Nullable, OnEventApiResponse } from "@origin/platform-types";
import { type UseQueryResult, useQuery, useMutation } from "@tanstack/react-query";
import { z } from "zod";
import { RadioGroupFieldVertical } from "./RadioGroupFieldVertical";
import { type BLLNotificationConfiguration } from "../models/BLLNotificationConfiguration";
import { SystemName } from "../constants/systemname";

type NotificationLookup = {
	systemName: string;
};

type SubscriptionFormState = {
	notificationType: Nullable<string>;
	notificationRecipient: Nullable<string>;
	notificationMethod: {
		email: boolean;
		sms: boolean;
	};
	recipientName: Nullable<string>;
	email: Nullable<string>;
	phone: Nullable<string>;
};

const subscriptionFormValidation = z
	.object({
		notificationType: z
			.string({
				errorMap: () => ({ message: "Please select a Notification Type" })
			}),
		notificationRecipient: z
			.string({
				errorMap: () => ({ message: "Please select a Recipient" })
			}),
		recipientName: z
			.string()
			.optional()
			.nullable(), // Will be required conditionally
		email: zodValidator.email.or(zodValidator.empty),
		phone: zodValidator.phone.or(zodValidator.empty),
		notificationMethod: z.object({
			email: z.boolean(),
			sms: z.boolean()
		})
	}).superRefine(async (schema, ctx) => {
		// Validate recipient name;
		if (schema.notificationRecipient === "notifyOther" && (schema.recipientName?.trim().length ?? 0) === 0) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Please provide a Recipient Name",
				path: ["recipientName"]
			});
		}
		// Validate notification method;
		if (!schema.notificationMethod.email && !schema.notificationMethod.sms) {
			ctx.addIssue({
				code: z.ZodIssueCode.custom,
				message: "Please select at least one notification method",
				path: ["notificationMethod"]
			});
		}
		// Validate notification email;
		if (schema.notificationMethod.email) {
			try {
				await zodValidator.email.parseAsync(schema.email);
			} catch (e) {
				let message = "Email is required";
				if (e instanceof Error) {
					try {
						const error = JSON.parse(e.message);
						message = error[0].message;
					} catch {
						message = message;
					}
				}
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: message,
					path: ["email"]
				});
			}
		}
		// Validate notification phone;
		if (schema.notificationMethod.sms) {
			try {
				await zodValidator.phone.parseAsync(schema.phone);
			} catch (e) {
				let message = "Phone is required";
				if (e instanceof Error) {
					try {
						const error = JSON.parse(e.message);
						message = error[0].message;
					} catch {
						message = message;
					}
				}
				ctx.addIssue({
					code: z.ZodIssueCode.custom,
					message: message,
					path: ["phone"]
				});
			}
		}
	});

type SubscriptionFormProps = {
	closeDialog: React.Dispatch<React.SetStateAction<boolean>>;
	isDialogOpen: boolean;
	query: UseQueryResult<OnEventApiResponse<BaseConsignment>, Error>;
};
export function SubscriptionForm({ closeDialog, isDialogOpen, query }: SubscriptionFormProps) {
	const theme = useTheme();
	const popup = usePopupAlert();
	const { userDetail } = useMyAccount();

	const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
	const [showForm, setShowForm] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState<BLLNotificationConfiguration>();

	const defaultValues: Partial<SubscriptionFormState> = {
		notificationRecipient: "notifyMe",
		notificationType: null,
		recipientName: null,
		email: userDetail?.email ?? null,
		phone: null
	};

	const [context, Form] = useForm<SubscriptionFormState>({
		defaultValues,
		validationSchema: subscriptionFormValidation
	});
	const { watch, setValue, handleSubmit } = context;
	const methodOnChange = () => context.clearErrors("notificationMethod");
	const notifyOther = watch("notificationRecipient") === "notifyOther";

	const { data: existingNotifications, isFetching, refetch } = useQuery<OnEventApiResponse<Array<BLLNotificationConfiguration>>>(
		[`BLLNotificationConfiguration/${query.data?.data.id}/Job`],
		() => httpService.get(`BLLNotificationConfiguration/${query.data?.data.id}/Job`),
		{
			enabled: !!query.data?.data.id,
			select: (payload) => ({
				...payload,
				data: payload.data?.filter((val) => val.active)
			})
		}
	);

	const mutation = useMutation(
		[`BLLNotificationConfiguration/${query.data?.data.id}/Job`],
		(body: Array<BLLNotificationConfiguration>) => httpService.post("BLLNotificationConfiguration/SaveList", body)
	);

	const [notificationLookup] = useLookupQuery<LookupItem<NotificationLookup>>({
		systemName: "NotificationType"
	});

	const [notificationTransportLookup] = useLookupQuery<LookupItem<NotificationLookup>>({
		systemName: "NotificationTransportType"
	});

	const notificationTypeID =
		notificationLookup?.data?.data.results.find(
			(val) => val.additionalData?.systemName === SystemName.NotificationType.ConsignmentNotification
		)?.id;

	const notificationTransportTypeID =
		notificationTransportLookup?.data?.data.results.find(
			(val) => val.additionalData?.systemName === SystemName.NotificationTransportType.Email
		)?.id;

	const title = "Subscribe to Consignment " + query?.data?.data.reference;
	const userDetailCombinedName = userDetail.firstName + " " + userDetail.lastName;
	const renderForm = !existingNotifications?.data?.length || showForm;
	const renderGrid = !!existingNotifications?.data?.length;

	const handleAddRecipientClick = () => {
		context.reset({
			...defaultValues,
			notificationRecipient: "notifyOther",
			email: null
		});
		setSelectedRow(undefined);
		setShowForm(true);
	};

	const handleOnEditClick = (data: BLLNotificationConfiguration) => {
		setSelectedRow(data);
		const state: Partial<SubscriptionFormState> = {
			notificationType: data.outForDeliveryOnly ? "outForDelivery" : "allNotifications",
			notificationMethod: {
				email: !!data.email,
				sms: !!data.phoneNumber
			},
			email: data.email,
			phone: data.phoneNumber
		};
		if (data.email === userDetail?.email) {
			state.notificationRecipient = "notifyMe";
			state.recipientName = null;
		} else {
			state.notificationRecipient = "notifyOther";
			state.recipientName = data.recipentName;
		}
		context.reset(state);
		setShowForm(true);
	};

	const handleOnDeleteClick = (data: BLLNotificationConfiguration) => {
		setSelectedRow(data);
		setShowConfirmationDialog(true);
	};

	const handleCancelDelete = () => {
		setShowConfirmationDialog(false);
		setSelectedRow(undefined);
	};

	const handleConfirmDelete = async () => {
		try {
			const body: Array<BLLNotificationConfiguration> = [
				{
					...selectedRow!,
					active: false
				}
			];
			await mutation.mutateAsync(body);
			setShowConfirmationDialog(false);
			setShowForm(false);
			setSelectedRow(undefined);
			context.reset(defaultValues);
			popup({ severity: "success", message: "Succesfully deleted subscription" });
			refetch();
		}
		catch (e) {
			popup({ severity: "error", message: "Failed to delete subscription" });
		}
	};

	const handleOnSubmit = () => {
		handleSubmit(
			async (state) => {
				const action = selectedRow ? "update" : "create";
				try {
					const payload: BLLNotificationConfiguration = {
						contactID: userDetail.id ? +userDetail.id : null,
						objectID: query.data?.data.id,
						objectType: "Job",
						notificationTypeID: notificationTypeID ? +notificationTypeID : 0,
						notificationTransportTypeID: notificationTransportTypeID ? +notificationTransportTypeID : 0,
						notificationTemplateID: null,
						recipentName: state.notificationRecipient === "notifyMe" ? userDetailCombinedName : state.recipientName,
						outForDeliveryOnly: state.notificationType === "outForDelivery",
						email: state.notificationMethod.email ? state.email : null,
						phoneNumber: state.notificationMethod.sms ? state.phone : null,
						active: true
					};
					if (selectedRow) {
						payload.id = selectedRow.id;
					}
					const body = [payload];
					await mutation.mutateAsync(body);
					setShowForm(false);
					setSelectedRow(undefined);
					context.reset(defaultValues);
					popup({ severity: "success", message: `Successfully ${action}d subscription` });
					refetch();
				}
				catch (e) {
					popup({ severity: "error", message: `Failed to ${action} subscription` });
				}
			}
		)();
	};

	const handleOnCancel = () => {
		if (selectedRow) {
			setSelectedRow(undefined);
			setShowForm(false);
			context.reset(defaultValues);
		}
		else if (!selectedRow && !!existingNotifications?.data?.length) {
			setShowForm(false);
			context.reset(defaultValues);
		}
		else {
			closeDialog(false);
		}
	}

	const notificationRecipient: RadioGroupFieldOption[] = [
		{ value: "notifyMe", label: "Notify Me", hasTextField: false },
		{ value: "notifyOther", label: "Notify Other", hasTextField: false }
	];

	const notificationType: RadioGroupFieldOption[] = [
		{ value: "outForDelivery", label: "Out for Delivery", hasTextField: false },
		{ value: "allNotifications", label: "All Notifications: Booked, In Transit, Out for Delivery and Delivered", hasTextField: false }
	];

	const columns: Array<GridColDef<BLLNotificationConfiguration>> = [
		{
			field: "recipentName",
			headerName: "Name",
			flex: 1,
			minWidth: 150
		},
		{
			field: "email",
			headerName: "Email",
			flex: 1,
			minWidth: 250
		},
		{
			field: "phoneNumber",
			headerName: "Phone",
			flex: 1,
			minWidth: 200
		},
		{
			field: "outForDeliveryOnly",
			headerName: "Notification Type",
			flex: 1,
			minWidth: 150,
			renderCell: (params: any) => (params.value ? "Out for Delivery" : "All")
		},
		{
			field: "actions",
			headerName: "Actions",
			headerAlign: "center",
			align: "right",
			flex: 1,
			minWidth: 80,
			renderCell: (params: any) => {
				return (
					<Box display="flex" alignItems="center">
						<IconButton
							color="inherit"
							aria-label="Edit"
							onClick={() => handleOnEditClick(params.row)}
						>
							<Edit />
						</IconButton>
						<IconButton
							color="inherit"
							aria-label="Edit"
							onClick={() => handleOnDeleteClick(params.row)}
						>
							<DeleteOutline />
						</IconButton>
					</Box>
				);
			}
		}
	];

	return (
		<Dialog
			maxWidth="md"
			fullWidth
			title={title}
			open={isDialogOpen}
			onClose={() => closeDialog(false)}
			sx={{
				"& .MuiDialog-paper": {
					minHeight: "625px"
				}
			}}
		>
			{isFetching ? (
				<Box
					width="100%"
					height="100%"
					flexGrow={1}
					display="flex"
					alignItems="center"
					justifyContent="center"
				>
					<CircularProgress />
				</Box>
			) : (
				<>
					{showConfirmationDialog && (
						<Dialog
							open={showConfirmationDialog}
							onClose={handleCancelDelete}
						>
							<DialogTitle>Confirm Deletion</DialogTitle>
							<DialogContent>
								<DialogContentText>
									Are you sure you want to delete this subscription?
								</DialogContentText>
							</DialogContent>
							<DialogActions>
								<Button
									variant="outlined"
									color="inherit"
									onClick={handleCancelDelete}
								>
									Cancel
								</Button>
								<Button
									variant="contained"
									color="error"
									disabled={mutation.isLoading}
									endIcon={mutation.isLoading && <CircularProgress color="inherit" size={16} />}
									onClick={handleConfirmDelete}
								>
									Delete
								</Button>
							</DialogActions>
						</Dialog>
					)}
					<Box flexGrow={1} display="flex" flexDirection="column">
						<Box
							display="flex"
							justifyContent="space-between"
							alignItems="center"
							padding={4}
							borderBottom={1}
							borderColor={theme.palette.grey[300]}
						>
							<Typography fontWeight={500} fontSize="1.25rem" color={theme.palette.common.black}>
								{title}
							</Typography>
							<IconButton
								onClick={() => closeDialog(false)}
								color="inherit"
								aria-label="Close"
							>
								<Close sx={{ flexGrow: 1 }} />
							</IconButton>
						</Box>
						{renderGrid && (
							<Box display="flex" flexDirection="column" gap={2} padding={4}>
								<Box display="flex" alignItems="center" gap={2}>
									<IconButton
										color="inherit"
										aria-label="Add"
										onClick={handleAddRecipientClick}
									>
										<Add />
									</IconButton>
									<Typography>Add Recipient</Typography>
								</Box>
								<DataGridPro<BLLNotificationConfiguration>
									hideFooter
									density="compact"
									columns={columns}
									rows={existingNotifications?.data ?? []}
									sx={{
										"& .MuiDataGrid-columnHeaders": {
											backgroundColor: alpha(hexToRgb(theme.palette.common.black), 0.8)
										},
										"& .MuiDataGrid-columnHeaderTitle": {
											color: theme.palette.common.white,
											fontWeight: 600
										},
										"& .MuiDataGrid-sortIcon": {
											color: theme.palette.common.white
										},
										"& .MuiDataGrid-menuIconButton": {
											color: theme.palette.common.white
										},
										"& .MuiDataGrid-filterIcon": {
											color: theme.palette.common.white
										},
										"& .MuiDataGrid-columnSeparator:hover": {
											color: darken(theme.palette.common.white, 0.3)
										},
										"& .MuiDataGrid-row": {
											backgroundColor: theme.palette.common.white
										},
										"& .MuiDataGrid-overlay": {
											backgroundColor: "white"
										}
									}}
								/>
							</Box>
						)}
						{renderForm && (
							<Form>
								<Box display="flex" flexDirection="column" gap={4} padding={4}>
									<Box display="flex" flexDirection="column" gap={2}>
										<Typography fontSize="1.125rem" fontWeight={500}>Notification Type</Typography>
										<RadioGroupFieldVertical
											name="notificationType"
											options={notificationType}
										/>
									</Box>
									<Box display="flex" flexDirection="column" gap={2}>
										<Typography fontSize="1.125rem" fontWeight={500}>Recipient</Typography>
										<RadioGroupField
											name="notificationRecipient"
											options={notificationRecipient}
											onChange={(_, value) => {
												if (value === "notifyOther") {
													setValue("email", null, { shouldValidate: true });
													setValue("phone", null, { shouldValidate: true });
												}
												else {
													setValue("recipientName", null, { shouldValidate: true });
													setValue("email", userDetail?.email ?? null, { shouldValidate: true });
												}
											}}
										/>
									</Box>
									{notifyOther && (
										<Box display="flex" flexDirection="column" gap={1.5}>
											<Typography fontSize="0.95rem" color={theme.palette.grey[700]}>
												Recipient Name
											</Typography>
											<FormInput name="recipientName" placeholder="Full Name" />
										</Box>
									)}
									<Box display="flex" flexDirection="column" gap={1.5}>
										<Typography fontSize="0.95rem" color={theme.palette.grey[700]}>
											Email Address
										</Typography>
										<FormInput name="email" placeholder="Email" />
									</Box>
									<Box display="flex" flexDirection="column" gap={1.5}>
										<Typography fontSize="0.85rem" color={theme.palette.grey[700]}>
											Phone Number
										</Typography>
										<FormPhoneInput name="phone" />
									</Box>
									<Box display="flex" flexDirection="column">
										<Typography fontSize="0.85rem" color={theme.palette.grey[700]}>
											Method
										</Typography>
										<Box display="flex" alignItems="center" gap={2}>
											<FormCheckbox
												name="notificationMethod.email"
												label="Email"
												onChange={methodOnChange}
											/>
											<FormCheckbox
												name="notificationMethod.sms"
												label={
													<>
														<Typography component="span">SMS&nbsp;&nbsp;</Typography>
														<Typography component="span" fontSize="0.85rem" color={theme.palette.grey[900]}>
															(selecting this option may incur SMS costs)
														</Typography>
													</>
												}
												onChange={methodOnChange}
											/>
										</Box>
										<FormHelperText error={!!context.formState.errors.notificationMethod}>
											{context.formState.errors.notificationMethod?.root?.message}
										</FormHelperText>
									</Box>
									<Box display="flex" justifyContent="flex-end" gap={2}>
										<Button
											variant="outlined"
											color="inherit"
											disabled={context.formState.isSubmitting}
											onClick={handleOnCancel}
										>Cancel</Button>
										<Button
											type="button"
											variant="contained"
											color="primary"
											disabled={context.formState.isSubmitting}
											endIcon={context.formState.isSubmitting && <CircularProgress color="inherit" size={16} />}
											onClick={handleOnSubmit}
										>{selectedRow ? "Update" : "Subscribe"}</Button>
									</Box>
								</Box>
							</Form>
						)}
						{renderGrid && !showForm && (
							<Box flexGrow={1} display="flex" alignItems="flex-end" justifyContent="flex-end" padding={4}>
								<Button
									variant="outlined"
									color="inherit"
									onClick={() => closeDialog(false)}
								>Cancel</Button>
							</Box>
						)}
					</Box>
				</>
			)}
		</Dialog>
	);
}
