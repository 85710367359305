export const SystemName = {
	ContainerTransportType: {
		Standard: "Standard",
		Side: "Side"
	},
	GeoCoordinateInformationType: {
		BoothsNotification: "BoothsNotification",
		GeneralEvent: "GeneralEvent",
		SignificantEvent: "SignificantEvent"
	},
	GoodsLoad: {
		Full: "Full",
		Empty: "Empty"
	},
	LocationType: {
		Address: "Address",
		Terminal: "Terminal",
		Area: "BLLArea",
		Port: "BLLPort",
		Park: "BLLPark",
		Client: "Client",
		ClientPortPark: "ClientPortPark"
	},
	MovementType: {
		Import: "Import",
		Export: "Export",
		Move: "Move"
	},
	NotificationType: {
		ConsignmentNotification: "ConsignmentNotification"
	},
	NotificationTransportType: {
		Email: "Email",
		Messaging: "Messaging",
		MessageMedia: "MessageMedia"
	}
};
